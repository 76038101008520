<template>
  <router-link to="/">
    <h1 class="link-body-emphasis">Software Sustainability Assessment Tool</h1>
  </router-link>
  <router-view/>
  <footer>
    <p>-- Bachelor Thesis of Michael Ahrer --</p>
  </footer>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

footer {
  margin-top: auto;
  vertical-align: bottom;
}
</style>
<script setup>
</script>
